import Emitter from 'utils/emitter'
import {GLOBAL_CONSTANTS, isTablet} from 'utils/constants'

/**
 * Adds functionality to the links in the home hero headlines
 */

const SELECTORS = {
    COMPONENT: '.js-home-hero',
    IMAGE_WRAPPER: '.js-image-wrapper',
    HERO_HEADLINE_WRAPPER: '.js-hero-headline-wrapper',
    HERO_LINKS: '.js-home-hero-link',
}
const imageWrapperWidth = 420
const imageWrapperHeight = 280
const hoverBuffer = 75

export default class HomeHero {
    /**
     * @desc Sets up classes and elements that get updated when
     * home hero headings are hovered on
     * @param {HTMLElement} el - Element for the home hero
     */

    constructor(el) {
        this.element = el
        this.imageWrapper = this.element.querySelector(SELECTORS.IMAGE_WRAPPER)
        this.heroHeadlineWrapper = this.element.querySelector(
            SELECTORS.HERO_HEADLINE_WRAPPER,
        )
        this.heroLinks = this.element.querySelectorAll(SELECTORS.HERO_LINKS)
        this.handleMouseLeave = this.handleMouseLeave.bind(this)
        this.handleMouseEnter = this.handleMouseEnter.bind(this)
        this.handleResize = this.handleResize.bind(this)
        this.hasInited = false
        this.activeImage = null
        this.activeParent = null

        this.initialize()
    }

    /**
     * @desc Runs once and listens for resize event. If tablet size or less,
     * don't even init this class.
     */
    initialize() {
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.handleResize)
        if (
            !isTablet() ||
            document.documentElement.dataset.whatintent !== 'touch'
        ) {
            this.registerEvents()
            this.hasInited = true
        }
    }

    /**
     * @desc Listen for mouse events on headline links so we can effect other elements
     */
    registerEvents() {
        this.heroLinks.forEach((link) => {
            link.addEventListener('mouseenter', this.handleMouseEnter)
            link.addEventListener('mouseleave', this.handleMouseLeave)
        })
    }

    /**
     * @desc Listen for mouseleave to remove active classes and hide the
     * image container
     * @param {Event} e - mouseleave event
     */
    handleMouseLeave() {
        this.heroHeadlineWrapper.classList.remove(
            GLOBAL_CONSTANTS.CLASSES.ACTIVE,
        )
        this.imageWrapper.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        if (this.activeParent) {
            this.activeParent.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        }
        if (this.activeImage) {
            this.activeImage.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        }
    }

    /**
     * @desc Listen for mouseenter to add active classes to display the headlines
     * and display the image collage wrapper
     * @param {Event} e - mouseleave event
     */
    handleMouseEnter(e) {
        const activeLink = e.currentTarget
        const activeImageClass = `.${activeLink.dataset.imageRef}`
        this.activeImage = this.element.querySelector(activeImageClass)
        this.activeParent = activeLink.parentElement
        const wrapperDems = this.heroHeadlineWrapper.getBoundingClientRect()
        const heightOffset = wrapperDems.height
        const widthOffset = wrapperDems.width
        const halfWrapperWidth = widthOffset / 2
        const halfWrapperHeight = heightOffset / 2
        const leftorRight =
            e.clientX > halfWrapperWidth
                ? imageWrapperWidth / 2
                : -imageWrapperWidth / 2
        const toporBottom =
            e.clientY - imageWrapperHeight > halfWrapperHeight
                ? -imageWrapperHeight - hoverBuffer
                : hoverBuffer

        this.imageWrapper.style.top = `${e.clientY + toporBottom}px`
        this.imageWrapper.style.left = `${e.clientX - leftorRight}px`

        this.heroHeadlineWrapper.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        if (this.activeParent) {
            this.activeParent.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        }
        if (this.activeImage) {
            this.activeImage.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        }
        this.imageWrapper.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
    }

    /**
     * @desc When the window is resized, either teardown this component
     * or initialized it again based on window size
     * @param {Event} e - mouseleave event
     */
    handleResize() {
        if (isTablet()) {
            this.tearDown()
            this.hasInited = false
        } else if (!this.hasInited) {
            this.registerEvents()
            this.hasInited = true
        }
    }

    /**
     * @desc Tear down the event listeners
     */
    tearDown() {
        if (this.hasInited) {
            this.heroLinks.forEach((link) => {
                link.removeEventListener('mouseenter', this.handleMouseEnter)
                link.removeEventListener('mouseleave', this.handleMouseLeave)
            })
        }
    }
}

/**
 * @desc Component definition for the HomeHero module
 */

export const HomeHeroComponent = {
    name: 'Home Hero',
    componentClass: SELECTORS.COMPONENT,
    Source: HomeHero,
}
