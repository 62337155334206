const SELECTORS = {
    COMPONENT: '.body-copy blockquote',
}

export default class QuoteShare {
    /**
     * @desc Initialize the share overlay
     * @param {HTMLElement} el - Parent blockquote element
     */

    constructor(element) {
        this.element = element

        this.onLoad()
        this.onMouseOver = this.onMouseOver.bind(this)
        this.onMouseOut = this.onMouseOut.bind(this)

        this.registerListeners()
    }

    registerListeners() {
        this.element.addEventListener('mouseover', this.onMouseOver)
        this.element.addEventListener('mouseout', this.onMouseOut)
    }

    onLoad() {
        if (!this.element.classList.contains('quote-share__has-loaded')) {
            this.element.classList.add('quote-share__has-loaded')
            let shareWrapper = document.createElement('div')
            let facebook = document.createElement('a')
            let twitter = document.createElement('a')
            let linkedIn = document.createElement('a')
            let email = document.createElement('a')

            // Facebook
            shareWrapper.classList.add('quote-share')
            facebook.dataset.quoteShare = 'facebook'
            facebook.innerHTML = '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid slice" xml:space="preserve"><path d="M18.768,7.465H14.5V5.56c0-0.896,0.594-1.105,1.012-1.105s2.988,0,2.988,0V0.513L14.171,0.5C10.244,0.5,9.5,3.438,9.5,5.32 v2.145h-3v4h3c0,5.212,0,12,0,12h5c0,0,0-6.85,0-12h3.851L18.768,7.465z" /></svg>'
            facebook.addEventListener('click', this.onClick)

            // Twitter
            twitter.dataset.quoteShare = 'twitter'
            twitter.innerHTML = '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g transform="translate(-12, -12)"><path d="M30.326 14h3.374L26.33 22.472 35 34h-6.789L22.894 27.008 16.81 34h-3.376l7.883-9.061L13 14h6.961L24.767 20.391 30.326 14zm-1.184 17.969h1.87L18.945 15.924h-2.006l12.203 16.045z"fill="#181818"/></g</svg>'
            twitter.addEventListener('click', this.onClick)

            // Linked In
            linkedIn.dataset.quoteShare = 'linkedIn'
            linkedIn.innerHTML = '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid slice" xml:space="preserve"><path d="M6.527,21.5h-5v-13h5V21.5z M4.018,6.5H3.988C2.478,6.5,1.5,5.318,1.5,4.019c0-1.329,1.008-2.412,2.547-2.412 c1.541,0,2.488,1.118,2.519,2.447C6.565,5.354,5.588,6.5,4.018,6.5z M15.527,12.5c-1.105,0-2,0.896-2,2v7h-5c0,0,0.059-12,0-13h5 v1.485c0,0,1.548-1.443,3.938-1.443c2.962,0,5.062,2.144,5.062,6.304V21.5h-5v-7C17.527,13.396,16.632,12.5,15.527,12.5z" /></svg>'
            linkedIn.addEventListener('click', this.onClick)

            // Email
            email.dataset.quoteShare = 'email'
            email.innerHTML = '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid slice" xml:space="preserve"><path d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z" /></svg>'
            email.addEventListener('click', this.onClick)

            shareWrapper.appendChild(facebook)
            shareWrapper.appendChild(twitter)
            shareWrapper.appendChild(linkedIn)
            shareWrapper.appendChild(email)

            this.element.appendChild(shareWrapper)
        }
    }

    onMouseOver() {
        this.element.classList.add('quote-share')
    }

    onMouseOut() {
        this.element.classList.remove('quote-share')
    }

    onClick(e) {
        e.preventDefault()
        let text = this.parentNode.parentNode.innerText
        let url = window.location.href

        switch (this.dataset.quoteShare) {
            case 'facebook':
                // @todo: sharer.php no longer accepts params so we should update this to use
                //  https://www.facebook.com/dialog/share
                window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url) + '&t=' + encodeURIComponent(text) + '', '', 'toolbar=0, status=0, width=900, height=500')
                break

            case 'twitter':
                // Twitter URLs are always shortened to 23 characters https://t.co/XXXXXXXXXX. Tweet is 280 max.
                // So text is max length 280 - 1 (space) - 23 (url) - 1 (space) - 14 (@IndexVentures) = 241
                if (text.length > 241) {
                    text = text.substring(0, 241 - 3) + '…'
                }
                text += ' ' + url + ' @IndexVentures'
                window.open('https://twitter.com/intent/tweet?text=' + encodeURIComponent(text), '', 'toolbar=0, status=0, width=650, height=360')
                break

            case 'linkedIn':
                window.open('https://www.linkedin.com/cws/share?url=' + encodeURIComponent(url) + '&token=&isFramed=true', 'linkedin', 'toolbar=no,width=550,height=550')
                break

            case 'email':
                location.href = 'mailto:?body=' + encodeURIComponent(text) + '&subject=' + encodeURIComponent(url)
                break
        }
    }
}

/**
 * @desc Test component definition used in module-loader
 */

export const QuoteShareComponent = {
    name: 'QuoteShare',
    componentClass: SELECTORS.COMPONENT,
    Source: QuoteShare,
}
