/*
    The slide transition time is 1000ms - if anything below changes that will need to change.
    e.g. time 0, 1 and 3 must equal the slide animation transition duration so the carousel
    resets on a blank to avoid resetting position during a playing video.

    |            |       Carousel 4250ms                          | resets the slide off left
    |     0      |      1       |        2        |      3        |             4            |
    | show image |  show blank |    show video    | slide to next |  set last slide to image |
    |            |             |          update progress         |
    |    250ms   |     250ms   |        3750ms     |    500ms     |         100ms            |
*/

import FounderCarousel, {CLASSES} from 'utils/FounderCarousel'

CLASSES.COMPONENT = '.js-founder-portraits-block'
CLASSES.COLUMN = '.js-founder-slide-col'

export default class FounderPortraits extends FounderCarousel {
    constructor(el) {
        let carouselLength = parseInt(el.querySelectorAll(CLASSES.CAROUSEL)[0].dataset.length)
        let allColumns = el.querySelectorAll(CLASSES.COLUMN)
        let columnCount = parseInt(allColumns.length)

        // Preview in page edit form loads js a couple of times and returns the correct innerWidth only on the first load,
        // on successive ones it's 0, so we end up with a mobile carousel (4 items) on desktop (8 items)
        // which breaks the js expecting more items in this.slides than there are. This tells it to append second set of
        // slides if we're in preview mode (innerWidth === 0)
        if (window.innerWidth >= 640 || window.innerWidth === 0) {
            if (columnCount <= carouselLength) {
                allColumns.forEach(column => {
                    // Duplicate the portrait columns so the carousel can loop
                    let clonedColumn = column.cloneNode(true)
                    let slide = clonedColumn.querySelectorAll(CLASSES.SLIDE)[0]
                    let delta = parseInt(slide.dataset.delta)
                    slide.dataset.delta = `${parseInt(slide.dataset.delta) + columnCount}`
                    slide.querySelectorAll('video')[0].setAttribute('preload', 'none')
                    column.parentElement.append(clonedColumn)

                    // Display an extra column to the left to display under the gradient on wide monitors
                    if (window.innerWidth >= 1800) {
                        if (delta === carouselLength - 1) {
                            let extraLeftColumn = column.cloneNode(true)
                            extraLeftColumn.classList.remove('js-founder-slide-col')
                            let extraLeftSlide = extraLeftColumn.querySelectorAll(CLASSES.SLIDE)[0]
                            extraLeftSlide.classList.add('js-founder-extra-slide')
                            extraLeftSlide.dataset.delta = '-1'
                            extraLeftSlide.querySelectorAll('video')[0].setAttribute('preload', 'none')
                            column.parentElement.prepend(extraLeftColumn)
                        }
                    }
                })
            }
        }
        super(el)

        this.init()

    }

    init() {
        // Adjust left position of carousel to account for extra slide
        // todo can all this be moved below super.init and be this.extraSlide????
        let extraSlide = this.founderPortraits.querySelectorAll('.js-founder-extra-slide')[0]
        if (extraSlide) {
            const extraSlideStyle = window.getComputedStyle(extraSlide)
            extraSlide.parentElement.parentElement.style.left = `-${extraSlideStyle.width}`
        }

        super.init()

        this.clickLatch = false
        this.touchStartClientX = 0
        this.swipeDistance = 70
        document.querySelectorAll('.founder__read-more').forEach(link => {
            // on a Mac sometimes click doesn't work, so we click the link inside the div
            link.addEventListener('click', () => {
                let anchorElement = link.querySelector('a')
                if (anchorElement) {
                    anchorElement.click()
                }
            })
        })
    }

    onSlideMouseEnter(event) {
        if (this.isDesktopMode()) {
            let delta = this.getSlideDelta(event)
            this.stopCarousel()
            if (this.founderIsOnScreen(delta)) {
                if (!this.founderIsOffScreenToLeft(delta)) {
                    this.setStateOverlay(delta)
                }
            }
            if (this.founderIsNext(delta)) {
                this.showNextBtn()
            }
            if (this.founderIsPrev(delta)) {
                this.showPrevBtn()
            }
            event.preventDefault()
            event.stopPropagation()
        }
    }

    onSlideMouseLeave(event) {
        if (this.isDesktopMode()) {
            let delta = this.getSlideDelta(event)
            this.hideNextBtn()
            this.hidePrevBtn()
            this.startCarousel(0)
            this.setStateImage(delta)
            event.preventDefault()
            event.stopPropagation()
        }
    }

    onSlideMouseDown(event) {
        let delta = this.getSlideDelta(event)

        // Prevent fast clicking
        if (!this.clickLatch && !this.isMobileMode()) {
            clearTimeout(this.clickLatchTimeoutId)
            this.clickLatchTimeoutId = window.setTimeout(() => this.clickLatch = false, 1000)
        }

        if (this.isDesktopMode() && !this.clickLatch) {
            this.desktopClick(delta)
        }

        if (this.isWideTabletMode()) {
            this.wideTabletClick(delta)
        }

        if (this.isTabletMode()) {
            this.tabletClick(delta)
        }

        if (this.isMobileMode()) {
            this.mobileClick(delta)
        }

        this.clickLatch = true
    }

    desktopClick(delta) {
        if (this.founderIsOnScreen(delta)) {
            if (this.getFounderState(delta) === 'video') {
                this.pauseCarousel()
                this.setStateOverlay(delta)
            }
        } else if (this.founderIsNext(delta)) {
            this.setActive(this.activeDelta + 1)
            this.enableAllFounders()
            this.disableNextOffScreenFounder()
        } else if (this.founderIsPrev(delta)) {
            if (this.activeDelta === 0) {
                this.resetCarouselReverse()
            } else {
                this.setActive(this.activeDelta - 1)
                this.enableAllFounders()
                this.disablePrevOffScreenFounder()
            }
        }
    }

    wideTabletClick(delta) {
        if (this.clickLatch) {
            return
        }
        if (this.founderIsNext(delta)) {
            return this.moveToFounderAndStart(this.activeDelta + 1)
        }
        if (this.getFounderState(delta) !== 'overlay') {
            this.pauseCarousel()
            this.setAllStateImage()
            this.setStateOverlay(delta)
        } else {
            this.startCarousel()
            this.setStateImage(delta)
        }
    }

    moveToFounderAndStart(delta) {
        this.setAllStateImage()
        this.resetTimers()
        this.setCarouselIsPlaying()
        this.setActive(delta).then(this.step0)
    }

    tabletClick(delta) {
        if (this.clickLatch) {
            return
        }
        if (delta === this.activeDelta) {
            if (this.getFounderState(delta) === 'overlay') {
                this.doStep1()
                this.setCarouselIsPlaying()
            } else {
                this.pauseCarousel()
                this.setCarouselIsPaused()
                this.setStateOverlay(delta)
            }
        } else {
            this.moveToFounderAndStart(delta)
        }
    }

    mobileClick(delta) {
        if (this.getFounderState(delta) === 'overlay') {
            this.doStep1()
            this.setCarouselIsPlaying()
        } else {
            this.pauseCarousel()
            this.setStateOverlay(delta)
        }
    }

    onSlideTouchStart(event) {
        if (this.isMobileMode()) {
            this.touchStartClientX = event.touches[0].clientX
        }
    }

    onSlideTouchEnd(event) {
        if (this.isMobileMode()) {
            let swipeX = event.changedTouches[0].clientX - this.touchStartClientX
            let jumpToOtherEnd = false
            let lastDelta = parseInt(this.carouselLength) - 1
            let nearestDelta = parseInt(this.getNearestDeltaFromCarouselScroll())
            let activeDelta = parseInt(this.activeDelta)
            if (activeDelta === 0 && nearestDelta === 0 && swipeX > this.swipeDistance) {
                this.setActive(this.carouselLength - 1)
                jumpToOtherEnd = true
            }
            if (activeDelta === lastDelta && nearestDelta === lastDelta && swipeX < -this.swipeDistance) {
                this.setActive(0)
                jumpToOtherEnd = true
            }
            clearTimeout(this.touchEndTimeoutId)
            this.touchEndTimeoutId = window.setTimeout((jumpToOtherEnd) => {
                let deltaScrolledTo = this.getNearestDeltaFromCarouselScroll()
                if (parseInt(deltaScrolledTo) !== parseInt(this.activeDelta) || jumpToOtherEnd) {
                    this.activeDelta = deltaScrolledTo
                    this.startCarousel(1)
                }
            }, 1000, jumpToOtherEnd)
        }
    }

    getNearestDeltaFromCarouselScroll() {
        let nearestDelta = 0
        let minDistance = 99999
        let distance = 0
        Object.keys(this.slides).forEach(delta => {
            distance = Math.abs(this.slides[delta].offset - this.founderCarousel.scrollLeft)
            if (distance < minDistance) {
                minDistance = distance
                nearestDelta = parseInt(delta)
            }
        })
        return nearestDelta
    }

    onCanPlayThrough(event) {
        super.onCanPlayThrough(event)
        let delta = this.getSlideDelta(event)
        // We can also play the duplicate videos
        let duplicateDelta = delta + parseInt(this.carouselLength)
        if (!Object.keys(this.slides).includes(duplicateDelta)) {
            return
        }
        let duplicateSlide = this.getSlide(duplicateDelta)
        if (duplicateSlide) {
            duplicateSlide.canPlayThrough = true
        }
    }

    setActive(delta) {
        const timeout = 1000
        if (this.isMobileMode()) {
            if (parseInt(delta) >= this.carouselLength) {
                delta = 0
            }
            const slide = this.getSlide(delta)
            if (slide) {
                this.founderCarousel.scrollTo({
                    top: 0,
                    left: slide.offset,
                    behavior: 'smooth',
                })
            }
        } else {
            if (parseInt(delta) >= this.carouselLength) {
                clearTimeout(this.resetCarouselTimeoutId)
                this.resetCarouselTimeoutId = window.setTimeout(this.resetCarousel, timeout)
            }
            this.enableAnimation()
            const slide = this.getSlide(delta)
            if (slide) {
                this.founderCarousel.style.transform = `translateX(-${slide.offset}px)`
            }
        }

        this.activeDelta = delta

        return new Promise((resolve) => setTimeout(resolve, timeout))
    }

    resetCarousel() {
        if (this.isMobileMode()) {
            return
        }
        this.disableAnimation()

        this.founderCarousel.style.transform = 'translateX(0)'
        this.activeDelta = 0
        this.setAllStateImage()
    }

    resetCarouselReverse() {
        this.disableAnimation()
        this.setAllStateImage()

        let lastSlide = this.getSlide(this.carouselLength)
        if (lastSlide) {
            this.founderCarousel.style.transform = `translateX(-${lastSlide.offset}px)`
        }
        this.activeDelta = this.carouselLength
        let previousSlide = this.getSlide(this.activeDelta - 1)
        if (previousSlide) {
            previousSlide.slide.dispatchEvent(new Event('mouseenter'))
        }

        clearTimeout(this.resetCarouselReverseTimeoutId)
        this.resetCarouselReverseTimeoutId = window.setTimeout(() => {
            this.setActive(this.activeDelta - 1)
            this.enableAllFounders()
            this.disablePrevOffScreenFounder()
        }, 1)
    }

    step0 = () => {
        this.setStateImage(this.activeDelta)
        clearTimeout(this.carousel[0].timeoutID)
        let timeout = this.firstPlayDelay(this.carousel[0].timeout)
        this.carousel[0].timeoutID = window.setTimeout(this.step1, timeout)
    }

    step1 = () => {
        this.setStateBlank(this.activeDelta)
        clearTimeout(this.carousel[1].timeoutID)
        this.carousel[1].timeoutID = window.setTimeout(this.step2, this.carousel[1].timeout)
    }

    step2 = () => {
        this.setStateVideo(this.activeDelta)
        clearTimeout(this.carousel[2].timeoutID)
        this.carousel[2].timeoutID = window.setTimeout(this.step3, this.carousel[2].timeout)
    }

    step3 = () => {
        let delta = this.activeDelta
        this.setActive(parseInt(this.activeDelta) + 1)
        clearTimeout(this.carousel[3].timeoutID)
        this.carousel[3].timeoutID = window.setTimeout(() => {
            this.step0()
            this.step4(delta)
        }, this.carousel[3].timeout)
    }

    step4 = (previousDelta) => {
        this.carousel[4].timeoutID = window.setTimeout(() => {
            this.setStateImage(previousDelta)
        }, this.carousel[4].timeout)
    }
}

export const FounderPortraitsComponent = {
    name: 'FounderPortraits',
    componentClass: CLASSES.COMPONENT,
    Source: FounderPortraits,
}
