/* global EVENT_PAYWALL_SETTINGS */
import 'whatwg-fetch'
import Emitter from 'utils/emitter'
import {MULTI_VIDEO_CONSTANTS} from './MultiVideo'

const CLASSES = {
    COMPONENT: '#template-microsite.event_status_restricted',
}

export const MICROSITES_RESTRICTED_CONSTANTS = {
    STORAGE_NAME: 'restricted_code',
    TIME_UPDATE_INTERVAL: 30, // How many seconds between tracking video progress
    PAGE_TIME_INTERVAL: 30000, // How many milliseconds between tracking page presence
}

export default class MicrositeRestricted {
    constructor(el) {
        this.wrapper = el
        this.code = undefined
        this.timeEnteredPage = this.getUnixtime()

        // Used in VIDEO_TIMEUPDATE
        this.videoThrottling = {}

        // Is there a code in the cookie? Use it!
        this.code = localStorage.getItem(MICROSITES_RESTRICTED_CONSTANTS.STORAGE_NAME)
        if (this.code) {
            el.classList.remove('event_status_restricted')
        }
        else {
            // No code, if its in the hash, check it!
            const match = window.location.hash.match(/code=(.+)/)
            if (!match || !match[1]) {
                this.showForm()
                return
            }
            this.code = match[1]

            localStorage.setItem(MICROSITES_RESTRICTED_CONSTANTS.STORAGE_NAME, this.code)

        }
        this.authorizeCode()

        const trackVideoProgress = (source, id, event, force = false) => {
            const k = `${source}.${id}`
            const existing = this.videoThrottling[k] || 0
            if (force || (event.seconds > (existing + MICROSITES_RESTRICTED_CONSTANTS.TIME_UPDATE_INTERVAL))) {
                this.videoThrottling[k] = event.seconds
                this.fireTrackingEvent(`distinct.video_progress.${k}`, event.seconds)
            }
        }

        Emitter.on(MULTI_VIDEO_CONSTANTS.EVENTS.VIDEO_PLAY, (source, id, event) => {
            const k = `${source}.${id}`
            this.fireTrackingEvent(`video_play.${k}`, event.seconds)
            trackVideoProgress(source, id, event, true)
        })
        Emitter.on(MULTI_VIDEO_CONSTANTS.EVENTS.VIDEO_PAUSE, (source, id, event) => {
            const k = `${source}.${id}`
            this.fireTrackingEvent(`video_paused.${k}`, event.seconds)

            trackVideoProgress(source, id, event, true)
        })
        Emitter.on(MULTI_VIDEO_CONSTANTS.EVENTS.VIDEO_TIMEUPDATE, trackVideoProgress)

        const trackTimeOnPage = () => {
            const timeNow = this.getUnixtime()
            // @TODO - put a throttle in here, in case the setInterval gets run multiple times due to Barba, etc.
            this.fireTrackingEvent('distinct.time_on_page', timeNow - this.timeEnteredPage)
        }
        this.timeEnteredPageHandler = setInterval(() => {
            trackTimeOnPage()
        }, MICROSITES_RESTRICTED_CONSTANTS.PAGE_TIME_INTERVAL)
        trackTimeOnPage()
    }

    tearDown() {
        clearInterval(this.timeEnteredPageHandler)
        Emitter.off(MULTI_VIDEO_CONSTANTS.EVENTS.VIDEO_PLAY)
        Emitter.off(MULTI_VIDEO_CONSTANTS.EVENTS.VIDEO_PAUSE)
        Emitter.off(MULTI_VIDEO_CONSTANTS.EVENTS.VIDEO_TIMEUPDATE)
    }

    getUnixtime() {
        return Math.floor(new Date().getTime() / 1000)
    }

    fireTrackingEvent(key, value) {
        fetch(`${EVENT_PAYWALL_SETTINGS.base_url}api/v1/guest/${this.code}/ping`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    key: key,
                    value: value,
                }),
            })
            .catch(error => {
                console.error(error)
            })
    }

    authorizeCode() {
        fetch(`${EVENT_PAYWALL_SETTINGS.base_url}api/v1/guest/${this.code}`, { method: 'POST' })
            .then(response => {
                if (response.ok) {
                    this.showPage()
                }
                else {
                    console.warn('Code authorization Failed')
                    localStorage.removeItem(MICROSITES_RESTRICTED_CONSTANTS.STORAGE_NAME)
                    this.showForm()
                }
                return response
            })
            .catch(error => {
                console.error(error)
                this.showForm()
            })
    }

    showPage() {
        this.wrapper.classList.remove('event_status_restricted')
        this.wrapper.querySelector('.restricted-form-wrapper').remove()
    }

    showForm() {
        // Remove the main page content; we dont want a video to autoplay in the background.
        [
            '.hero-content', '.has-background', '.body-content',
        ].forEach(sel => {
            const e = this.wrapper.querySelector(sel)
            if (e) { e.remove() }
        })

        this.wrapper.classList.add('show-form')

        // Setup form AJAX. Very similar to newsletter popup JS.
        this.formElement = this.wrapper.querySelector('.restricted-form form')
        this.formElement.addEventListener('submit', this.formSubmitHandler.bind(this))

        // Only enable the submit button if the value looks email-ish
        const submitButton = this.formElement.querySelector('input[type=submit]')
        this.formElement.querySelector('input[name=email]').addEventListener('input', function (e) {
            // JS needs us to set disabled, so "negate" the regex that's looking for an email address.
            submitButton.disabled = !(/^\S+@\S+\.\S+$/.test(e.target.value))
        })
    }

    formSubmitHandler(event) {
        event.preventDefault()

        this.disableRegisterButton('Sending...')

        this.formElement.querySelectorAll('span.error').forEach(function (el) {
            el.remove()
        })

        fetch(`${EVENT_PAYWALL_SETTINGS.base_url}api/v1/guest`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: event.target.elements.email.value,
                }),
            })
            .then(response => {
                if (response.ok) {
                    this.createMessage('success', 'Thank you for registering. Check your email for your unique link to join this event.')
                    this.disableRegisterButton('Registered')
                }
                else {
                    if (response.body && response.body.errors) {
                        this.createMessage('error', response.body.errors.join(', '))
                    }
                    else {
                        this.createMessage('error', 'There was a problem submitting your email.')
                    }
                    this.enableRegisterButton()
                }
            })
            .catch(error => {
                console.error('ERROR', error)
                this.enableRegisterButton()
            })
    }

    disableRegisterButton(message) {
        const submitButton = this.formElement.querySelector('input[type=submit]')
        if (!submitButton.disabled) {
            submitButton.dataset.originalLabel = submitButton.value
        }
        submitButton.disabled = true
        submitButton.value = message
    }
    enableRegisterButton() {
        const submitButton = this.formElement.querySelector('input[type=submit]')
        if (submitButton.disabled) {
            submitButton.disabled = false
            submitButton.value = submitButton.dataset.originalLabel
        }
    }

    createMessage(type, message) {
        const errorMessage = document.createElement('span')
        errorMessage.classList.add('message', type)
        errorMessage.innerText = message
        // Add this error message to the end of the form so it comes under the button.
        this.formElement.append(errorMessage)
    }
}

export const MicrositeRestrictedComponent = {
    name: 'MicrositeRestricted',
    componentClass: CLASSES.COMPONENT,
    Source: MicrositeRestricted,
}
