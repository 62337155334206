import {GLOBAL_CONSTANTS} from 'utils/constants'
import 'what-input'
import smoothscroll from 'smoothscroll-polyfill'
import {modules} from 'utils/module-loader'
import Emitter from 'utils/emitter'
import isLoaded from 'utils/isLoaded'
import Barba from 'utils/Barba'

import Nav from 'components/Nav'
import LazyLoad from 'components/LazyLoad'
import attachNavigationClickEvents from './utils/attachNavigationClickEvents'
import attachOutboundLinkClickEvents from './utils/attachOutboundLinkClickEvents'
import Bugsnag from '@bugsnag/js'
import bugsnagConfig from './utils/bugsnagConfig'
import SentryWrapper from './utils/SentryWrapper'

export default class App {
    static Nav
    static LazyLoad

    constructor() {
        if (bugsnagConfig.apiKey.length === 32) {
            Bugsnag.start(bugsnagConfig)
        }
        new SentryWrapper()
        this.detectJs()
        this.detectFrame()
        smoothscroll.polyfill()

        this.registerEvents()

        this.initNav()
        this.initLazyLoad()

        if (BARBA_ENABLED) {
            this.Barba = new Barba()
        }
    }

    leavePage() {
        this.instances.forEach((instance) => {
            instance.refs.forEach((ref) => {
                if (ref.__proto__.tearDown) {
                    ref.tearDown()
                }
            })
        })
    }

    /**
     * @desc Initializes the main nav. Should only occur once
     * to avoid multiple bindings.
     */
    initNav() {
        this.constructor.Nav = new Nav()
    }

    initLazyLoad() {
        this.constructor.LazyLoad = new LazyLoad()
    }

    trackScrollDepth() {
        if (!document.body || typeof window.gtag === 'undefined') {
            return
        }
        window.scrollDepth = window.scrollDepth || {}
        let lastMark = window.scrollDepth.lastMark || 0
        let checkMarks = [0, 25, 50, 75, 90]
        let scrollPercent = Math.round((window.scrollY / document.body.clientHeight) * 100)
        let scrollPercentRounded = checkMarks.reduce((prev, curr) => {
            return (Math.abs(curr - scrollPercent) < Math.abs(prev - scrollPercent)) ? curr : prev
        })
        if (scrollPercentRounded !== lastMark) {
            window.scrollDepth.lastMark = scrollPercentRounded
            window.gtag('event', 'scroll_depth', {
                'event_category': 'scroll',
                'event_label': 'scroll_depth',
                'value': scrollPercentRounded,
            })
        }
    }

    /**
     * @desc If JS is available,
     * remove the no-js class from the html element.
     */
    detectJs() {
        document.documentElement.classList.remove('no-js')
    }

    /**
     * @desc If serving page within a frame
     * add a class to the html element.
     */
    detectFrame() {
        if (window !== window.top) {
            document.documentElement.classList.add('in-frame')
        }
    }

    /**
     * @desc Create all instances of classes that are paired with
     * javascript components in the modules Array.
     * @return {Array.<Object>} Array of all module objects.
     */
    registerInstances() {
        return modules.reduce((result, module) => {
            const elements = Array.from(
                document.querySelectorAll(module.componentClass),
            )
            const references = elements.map((el) => new module.Source(el))

            const instanceObj = {
                name: module.name,
                refs: references,
            }

            if (instanceObj.refs.length) {
                result.push(instanceObj)
            }
            return result
        }, [])
    }

    /**
     * @desc - These events get instantiated once and bound to the window
     * so that there aren’t multiple events firing from each page module
     * @fires <App>#[resize]
     * @fires <App>#[scroll]
     * @fires <App>#[keydown]
     */
    registerEvents() {
        /** Fire resize event to emitter when window resizes */
        window.addEventListener('resize', () => {
            Emitter.emit('resize')
        })
        /** Fire scroll event to emitter when window scroll */
        window.addEventListener('scroll', () => {
            this.trackScrollDepth()
            Emitter.emit('scroll')
        })
        /** Fire keydown event to emitter on window keydown */
        window.addEventListener('keydown', (e) => {
            Emitter.emit('keydown', e)
        })
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.PAGE_AFTER_ENTER, () => {
            this.instances = this.registerInstances()
            attachNavigationClickEvents()
            attachOutboundLinkClickEvents()
        })
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.PAGE_LEAVE, () => {
            this.leavePage()
        })
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.PAGE_LOADING, (data) => {
            Emitter.emit(GLOBAL_CONSTANTS.EVENTS.LAZY_LOAD_IMAGE_SCAN, data.next.container)
        })
    }


}

isLoaded(App)
