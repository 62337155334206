/* global gtag */
import Cookies from 'js-cookie'
import App from '../app.entry'
import barba from '@barba/core'
import Emitter from 'utils/emitter'
import {GLOBAL_CONSTANTS} from 'utils/constants'

const CLASSES = {
    COMPONENT: '.real-time-search__wrapper',
}

export default class RealTimeSearch {
    constructor(el) {
        const FADE_DURATION = 150

        // todo i used to set this to 999
        el.maxInnerResultWindow = 100
        el.defaultResultsPerSection = 4
        el.defaultResultsPerPage = 20
        // Results with a lower search score won't be displayed
        el.minScore = 5
        el.lastSearch = {
            searchType: '',
            query: '',
            facetsMust: [],
            facetsShould: [],
            timeout: 0,
            resultsPerSection: 0,
            page: 0,
        }
        el.pager = {
            'show': true,
            'html': '',
            'prev': {
                'show': true,
                'html': '',
            },
            'dots': {
                'html': '',
            },
            'next': {
                'show': true,
                'html': '',
            },
        }

        el.searchFieldAll = el.querySelectorAll('.js-search-input')
        el.realTimeSearchAll = el.querySelectorAll('.real-time-search')
        el.searchTitleAll = document.querySelectorAll('.navigation__search__label')
        el.searchCloseButtonAll = el.querySelectorAll('.search-close-button')
        el.searchBackButtonAll = el.querySelectorAll('.search-back-button')
        el.searchInProgressAll = el.querySelectorAll('.search-in-progress')
        el.searchResultsAll = el.querySelectorAll('.search-results')
        el.closeDuringSearchAll = document.querySelectorAll('.close-during-search')
        el.headerHeaderSearchAll = el.querySelectorAll('.header .header-search')
        el.headerAll = el.querySelectorAll('.region-header .header')
        el.body = document.querySelectorAll('body')[0]
        el.searchTriggerLinkAll = el.querySelectorAll('button[data-trigger-search], a[data-trigger-search]')
        el.searchFieldFormAll = el.querySelectorAll('form.search-form')
        el.contentTypeSectionAll = el.realTimeSearchAll[0].querySelectorAll('.content-type-section')
        el.pagerElement = el.querySelector('#real-time-search-pager')

        Emitter.on(GLOBAL_CONSTANTS.EVENTS.PAGE_BEFORE_LEAVE, () => {
            App.Nav.closeSearchBar()
        })

        window.addEventListener('load', () => {
            searchFromUrlParams()
        })

        Emitter.on('close-search-content', () => {
            closeSearchContent()
        })

        let searchDelay, searchBoxCloseDelay
        let facetsMust, facetsShould
        let searchTitleIsChangingTo = ''
        let currentState = ''

        el.fadeInAll = (elementAll) => {
            elementAll.forEach(element => el.fadeIn(element))
        }

        el.fadeIn = (element) => {
            element.classList.add('show')
        }

        el.fadeOutAll = (elementAll) => {
            elementAll.forEach(element => el.fadeOut(element))
        }

        el.fadeOut = (element) => {
            element.classList.remove('show')
        }

        el.scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        }

        let escapeReservedWordCharacters = function(word) {
            word = word.trim()
            let plainWord = word.replace(/\+|-|=|&&|\|\||>|<|!|\(|\)|\{|\}|\[|\]|\^|"|~|\*|\?|:|\\|\/|'/g, '')

            // If plainWord length is 0 it probably means the user is typing a
            // modified search query like -london or +design or whatever. Just
            // having a + or - in the search query breaks the search so we omit it.
            // This will also be true if word is empty
            if (plainWord.length === 0) {
                return ''
            }

            // https://www.elastic.co/guide/en/elasticsearch/reference/7.12/query-dsl-query-string-query.html#_reserved_characters
            // so we need to remove '<' and '>'
            word = word.replace(/>|</g, '')

            // and we need to escape = && || ! ( ) { } [ ] ^ " ~ * ? : \ /
            // for some reason cannot replace/replaceAll with "\[" - it looses "\"
            let toEscape = ['&', '|','!', '(', ')', '{', '}', '[', ']', '^', '"', '~', '*', '?', ':', '\\', '/']
            let characters = word.split('').map((char)=>toEscape.includes(char) ? '\\' + char : char )

            return characters.join('')
        }

        let escapeReservedQueryCharacters = function(query) {
            let queryArray = query.trim().split(' ')

            return queryArray.map((word) => escapeReservedWordCharacters(word)).join(' ')
        }

        let search = (query, facetsMust, facetsShould, resultsPerSection, resultsPerPage, page) => {
            query = escapeReservedQueryCharacters(query)
            if (process.env.NODE_ENV === 'development') {
                console.log({query, facetsMust, facetsShould, resultsPerSection, resultsPerPage, page})
            }
            page = page === undefined ? 1 : page
            let minScore = el.minScore

            return new Promise(function (resolve, reject) {
                let payload = {page, query, minScore}
                if (facetsMust && facetsMust.length) {
                    payload['facetsMust'] = facetsMust
                }
                if (facetsShould && facetsShould.length) {
                    payload['facetsShould'] = facetsShould
                }

                const xhr = new XMLHttpRequest()
                xhr.open('post', '/site_search')
                xhr.onload = function () {
                    if (xhr.status >= 200 && xhr.status < 300) {
                        resolve(JSON.parse(xhr.response))
                    } else {
                        reject({
                            status: xhr.status,
                            statusText: xhr.statusText,
                        })
                    }
                }
                xhr.onerror = function () {
                    reject({
                        status: xhr.status,
                        statusText: xhr.statusText,
                    })
                }
                xhr.setRequestHeader('X-CSRFToken', Cookies.get('csrf_token'))
                xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
                xhr.send(JSON.stringify(payload))
            })
        }

        let setSearchTitle = (title) => {
            // Prevents the title fading in and out with the same text.
            if (searchTitleIsChangingTo !== title) {
                searchTitleIsChangingTo = title
                el.searchTitleAll.forEach(searchTitle => {
                    el.fadeOut(searchTitle)
                    setTimeout(() => {
                        searchTitle.innerHTML = title
                        el.fadeIn(searchTitle)
                    }, FADE_DURATION)
                })
            }
        }

        let resetSearchTitle = () => {
            let title = el.searchTitleAll[0].dataset['default']
            if (title) {
                setSearchTitle(title)
            }
        }

        let setSearchState = (state) => {
            switch (state) {
                case 'search-no-results':
                    if (state !== currentState) {
                        el.fadeOutAll(el.searchInProgressAll)
                        el.fadeOutAll(el.searchResultsAll)
                        currentState = state
                    }
                    break
                case 'search-in-progress':
                    if (state !== currentState) {
                        el.fadeInAll(el.searchInProgressAll)
                        el.fadeOutAll(el.searchResultsAll)
                        el.scrollToTop()
                        currentState = state
                    }
                    break
                case 'search-results':
                    if (state !== currentState) {
                        el.fadeOutAll(el.searchInProgressAll)
                        el.fadeInAll(el.searchResultsAll)
                        currentState = state
                    }
                    break
                default:
                    break
            }
        }

        let showHidePlaceholder = () => {
            if (window.outerWidth < 768) {
                el.searchFieldAll[0].removeAttribute('placeholder')
            } else {
                el.searchFieldAll[0].setAttribute('placeholder', 'Search')
            }
        }

        // todo: Google analytics tracking.
        let gaTrackPageView = (query) => {
            if (typeof gtag !== 'undefined') {
                gtag('event', 'page_view', {
                    'page_title': `Search popup: ${query}`,
                    'page_path': `/search/${query}?search=${query}`,
                })
            }
        }
        let gaTrackEventSearch = (query, totalResults) => {
            if (typeof gtag !== 'undefined') {
                gtag('event', 'site_search', {
                    'event_category': 'Site Search',
                    'event_label': 'Predictive Search',
                    'value': query,
                    'results': totalResults,
                })
            }
        }
        let gaTrackEventResultClick = (query, section, link) => {
            if (typeof gtag !== 'undefined') {
                gtag('event', 'site_search_result_click', {
                    'event_category': 'Site Search',
                    'event_label': 'Result Click',
                    'value': [section, link, 'Predictive'].join(' - '),
                })
            }
        }
        let gaBindEventResultClick = (event) => {
            let query = el.searchFieldAll[0].value.trim()
            let linkElement = event.target.closest('a')
            let sectionElement = event.target.closest('.content-type-section')
            let link = linkElement ? linkElement.title : ''
            let section = sectionElement ? sectionElement.dataset.section : ''
            gaTrackEventResultClick(query, section, link)
            event.stopPropagation()
        }

        let openSearchContent = () => {
            let query = el.searchFieldAll[0].value.trim()
            console.log(query)
            if (query && query.length) {
                setSearchUrl(query)
            }

            el.body.classList.add('real-time-search-active')
        }

        let closeSearchContent = () => {
            if (el.body.classList.contains('real-time-search-active')) {
                setSearchUrl('')
                el.searchFieldAll[0].blur()
                el.body.classList.remove('real-time-search-active')
            }
        }

        let nodeTypeSearch = (event, type, facetsMust, facetsShould, page) => {
            page = page === undefined ? 1 : page
            facetsMust = []
            facetsMust.push({'content_type': type})
            let query = el.searchFieldAll[0].value.trim()
            executeSearch(event, 'queryAndFacet', query, facetsMust, facetsShould, 0, el.defaultResultsPerSection, el.defaultResultsPerPage, page)
        }

        // Adds or removes the ?search=query param to the url without affecting any other params or hash values
        let setSearchUrl = (query) => {
            // let title = document.querySelector('title')
            let url = new URL(window.location.href)
            if (query && query.length) {
                url.searchParams.set('search', query)
            } else {
                url.searchParams.delete('search')
            }
            // This is causing a lot of serious bugs now that the search results use barba transitions.
            // It breaks the browser back button functionality and goes back to a blank page.
            // history.pushState({}, title, url.toString())
        }

        let searchFromUrlParams = () => {
            const urlParams = new URLSearchParams(window.location.search)
            const urlSearchQuery = urlParams.get('search')
            if (urlSearchQuery && urlSearchQuery !== '') {
                el.searchFieldAll[0].value = urlSearchQuery
                App.Nav.openSearchBar()
                openSearchContent()
                let query = el.searchFieldAll[0].value.trim()
                executeSearch(new Event('null'), 'query', query, [], [], 0, 4)
            }
        }

        /**
         * executeSearch
         * @param  String searchType 'query' || 'facet' || 'queryAndFacet'
         * @param  String query      The search query from the search bar.
         * @param  Object facets     Facets to pass to elastic search
         * @param  Int    timeout    Timeout in milliseconds
         */
        let executeSearch = function (event, searchType, query, facetsMust, facetsShould, timeout, resultsPerSection, resultsPerPage, page) {
            if (process.env.NODE_ENV === 'development') {
                console.groupCollapsed('Execute Search')
                console.log('Search Arguments', arguments)
                console.trace()
                console.groupEnd()
            }
            timeout = timeout === undefined ? 500 : timeout
            facetsMust = facetsMust === undefined ? [] : facetsMust
            facetsShould = facetsShould === undefined ? [] : facetsShould
            resultsPerSection = resultsPerSection === undefined ? el.defaultResultsPerSection : resultsPerSection
            resultsPerPage = resultsPerPage === undefined ? el.defaultResultsPerPage : resultsPerPage
            page = page === undefined ? 1 : page
            let isNodeTypeSearch = Object.keys(facetsMust).length
            let resultSectionMaxScore = []

            // We won't search again if the trimmed query is the same as the
            // trimmed last query... eg if you press a key which doesn't affect
            // the query, like arrows or space.
            let searchHasChanged = false

            if (searchType !== el.lastSearch.searchType) {
                searchHasChanged = true
            }
            if (searchType === 'query' || searchType === 'queryAndFacet') {
                if (query !== el.lastSearch.query) {
                    searchHasChanged = true
                }
            }
            if (searchType === 'facet' || searchType === 'queryAndFacet') {
                if (JSON.stringify(facetsMust) !== JSON.stringify(el.lastSearch.facetsMust)) {
                    searchHasChanged = true
                }
                if (JSON.stringify(facetsShould) !== JSON.stringify(el.lastSearch.facetsShould)) {
                    searchHasChanged = true
                }
            }

            if (el.lastSearch) {
                if (el.lastSearch.page && page !== el.lastSearch.page) {
                    searchHasChanged = true
                }
            }

            el.lastSearch = {
                searchType: searchType,
                query: query,
                facetsMust: facetsMust,
                facetsShould: facetsShould,
                timeout: timeout,
                resultsPerSection: resultsPerSection,
                resultsPerPage: resultsPerPage,
                page: page,
            }

            if (searchHasChanged) {
                setSearchUrl(query)

                if (query.length || Object.keys(facetsMust).length || Object.keys(facetsShould).length) {
                    setGoBackVisibility()
                    setSearchTitle('Searching')
                    setSearchState('search-in-progress')
                }

                window.clearTimeout(searchDelay)

                searchDelay = window.setTimeout(function () {
                    if (query.length || Object.keys(facetsMust).length || Object.keys(facetsShould).length) {
                        openSearchContent()
                    } else {
                        resetSearchTitle()
                        setSearchState('search-no-results')
                        return
                    }

                    search(query, facetsMust, facetsShould, resultsPerSection, resultsPerPage, page).then(function (body) {
                        if (!body.hits.total.value) {
                            setSearchTitle('Sorry, no results found.')
                            setSearchState('search-no-results')
                        } else {
                            if (!isNodeTypeSearch) {
                                let resultsString = parseInt(body.hits.total.value).toLocaleString()
                                setSearchTitle(`${resultsString} Results`)
                                setSearchState('search-results')
                            }
                        }

                        el.contentTypeSectionAll.forEach(contentTypeSection => {
                            contentTypeSection.style.display = 'none'
                            let ulAll = contentTypeSection.querySelectorAll('ul')
                            ulAll.forEach(ul => ul.innerHTML = '')
                        })
                        if (searchType === 'query') {
                            body.aggregations.types.buckets.forEach((bucket) => {
                                if (bucket.key !== 'wagtailcore.Page' && bucket.key !== 'custom.CustomPage') {
                                    resultSectionMaxScore.push({key: bucket.key, score: bucket.top_types.hits.max_score})
                                }
                                renderBucket(bucket, resultsPerSection)
                            })
                            setSectionOrder(resultSectionMaxScore)
                        }
                        // Todo I don't think we use the facet search on it's own, I think it was for canned searches
                        if (searchType === 'queryAndFacet' || searchType === 'facet') {
                            renderHits(body, resultsPerPage)
                        }

                        setGoBackVisibility()
                        gaTrackPageView(query)
                        gaTrackEventSearch(query, body.hits.total.value)

                    }, function (error) {
                        console.trace(error.message)
                    })
                }, event.keyCode === 13 ? 0 : timeout) // Press Enter.
            }
        }

        let renderHit = (hit, nodeType) => {
            let sectionAll = el.realTimeSearchAll[0].querySelectorAll(`[data-section="${nodeType}"]`)
            let section = sectionAll[0]
            let sectionListAll = section.querySelectorAll('ul')

            let resultLink = document.createElement('a')
            resultLink.href = hit._source.url_filter
            resultLink.target = '_self'
            resultLink.dataset.score = hit._score

            let resultElement = document.createElement('li')
            resultElement.classList.add('result')
            resultElement.append(resultLink)

            let title = ''
            let snippet = ''
            let image = ''

            switch (nodeType) {
                case 'startup_jobs.JobModel':
                    title = renderResultTitle(`${hit._source.job_company_title}: ${hit._source.title}`)
                    resultLink.title = `${hit._source.job_company_title}: ${hit._source.title}`
                    snippet = renderResultSnippet(hit._source.search_snippet_filter)
                    if (hit._source.hasOwnProperty('job_application_url_filter') && hit._source.job_application_url_filter) {
                        let externalUrl = hit._source.job_application_url_filter
                        resultLink.href = externalUrl
                        if (externalUrl.indexOf('indexventures.com') === -1) {
                            resultLink.target = '_blank'
                        }
                    }
                    break
                case 'perspectives.PerspectivesDetailPage':
                    title = renderResultTitle(hit._source.title)
                    resultLink.title = hit._source.title
                    snippet = renderResultSnippet(hit._source.perspectives_perspectivesdetailpage__search_snippet_filter)
                    image = renderResultImage(hit._source.perspectives_perspectivesdetailpage__search_image_url_filter, hit._source.title)
                    if (hit._source.hasOwnProperty('perspectives_perspectivesdetailpage__external_url_filter') && hit._source.perspectives_perspectivesdetailpage__external_url_filter) {
                        let externalUrl = hit._source.perspectives_perspectivesdetailpage__external_url_filter
                        resultLink.href = externalUrl
                        if (externalUrl.indexOf('indexventures.com') === -1) {
                            resultLink.target = '_blank'
                        }
                    }
                    break
                case 'press.PressDetailPage':
                    title = renderResultTitle(hit._source.title)
                    resultLink.title = hit._source.title
                    snippet = renderResultSnippet(hit._source.press_pressdetailpage__search_snippet_filter)
                    image = renderResultImage(hit._source.press_pressdetailpage__search_image_url_filter, hit._source.title)
                    break
                case 'companies.Company':
                    title = renderResultTitle(hit._source.title)
                    resultLink.title = hit._source.title
                    snippet = renderResultSnippet(hit._source.companies_company__search_snippet_filter)
                    break
                case 'team.TeamMember':
                    title = renderResultTitle(hit._source.title)
                    resultLink.title = hit._source.title
                    snippet = renderResultSnippet(hit._source.team_teammember__search_snippet_filter)
                    image = renderResultImage(hit._source.team_teammember__search_image_url_filter, hit._source.title)
                    break
                default:
                    break
            }
            resultLink.innerHTML = `<div>${title}${snippet}</div><div>${image}</div>`
            if (resultLink.target === '_self') {
                resultLink.onclick = event => {
                    // Don't barba transition from the Vue apps to other pages.
                    if (!window.location.href.match(/rewarding-talent|destination-europe|scaling-through-chaos|optionplan|expansionplan|destination-usa|startup-jobs/g)) {
                        barba.go(event.currentTarget.href, 'barba', event)
                    }
                }
            }

            try {
                sectionListAll[0].append(resultElement)
            }
            catch (e) {
                console.error(e.message)
            }
        }

        let renderHits = (body, resultsPerPage) => {
            let nodeType = body.hits.hits[0]._source.content_type.filter(type => type !== 'wagtailcore.Page')[0]
            let section = el.realTimeSearchAll[0].querySelectorAll(`[data-section="${nodeType}"]`)[0]
            let sectionTitleAll = section.querySelectorAll('.content-type-name')
            let searchTitle = section.dataset.searchTitle.replace('@total', body.hits.total.value)
            section.style.display = 'block'
            body.hits.hits.forEach((hit) => {
                renderHit(hit, nodeType)
            })
            setSearchTitle(searchTitle)
            setSearchState('search-results')
            // todo we might want this to be visible now
            sectionTitleAll[0].style.display = 'none'
            let maxPages = Math.ceil(body.hits.total.value / resultsPerPage)

            renderPager(maxPages)
        }

        let renderBucket = (bucket, resultsPerSection) => {
            let selector = bucket.key
            let sectionAll = el.realTimeSearchAll[0].querySelectorAll(`[data-section="${selector}"]`)

            // Wipe pager
            el.pagerElement.innerHTML = ''

            if (sectionAll.length) {
                let section = sectionAll[0]
                section.style.display = 'block'
                let sectionListAll = section.querySelectorAll('ul')
                let sectionTitleAll = section.querySelectorAll('.content-type-name')
                let showAllText = section.dataset.showAllText.replace('@total', bucket.doc_count)
                let nodeType = bucket.key

                bucket.top_types.hits.hits.forEach((hit) => {
                    renderHit(hit, nodeType)
                })

                sectionTitleAll.forEach(sectionTitle => sectionTitle.style.display = 'block')

                if (bucket.doc_count > resultsPerSection) {
                    // Show all 123 Perspectives button
                    let showAllElement = document.createElement('li')
                    showAllElement.classList.add('show-all-results')
                    showAllElement.innerHTML = `<div class="link"><a href="#">${showAllText}</a></div>`
                    sectionListAll[0].append(showAllElement)
                    sectionListAll[0].querySelectorAll('.show-all-results a')[0].addEventListener('click', (event) => {
                        if (nodeType === 'startup_jobs.JobModel') {
                            closeSearch()
                            window.location = '/startup-jobs/' + el.lastSearch.query
                        } else {
                            nodeTypeSearch(event, nodeType, el.lastSearch.facetsMust, el.lastSearch.facetsShould)
                        }
                        event.preventDefault()
                    })
                }
            }
        }

        // Method called when you click the next button on the company browse facet.
        let onPagerNext = (event) => {
            let pageNumber = parseInt(el.lastSearch.page) + 1
            changePageTo(event, pageNumber)
        }

        let onPagerDot = (event) => {
            let pageNumber = event.target.dataset['pageNumber']
            if (pageNumber && pageNumber.length) {
                pageNumber = parseInt(pageNumber)
                changePageTo(event, pageNumber)
            }
        }

        // Method called when you click the prev button on the company browse facet.
        let onPagerPrev = (event) => {
            let pageNumber = parseInt(el.lastSearch.page) - 1
            if (pageNumber < 1) {
                pageNumber = 1
            }
            changePageTo(event, pageNumber)
        }

        // Method called when you click a blank dot on the company browse facet.
        let changePageTo = (event, pageNumber) => {
            event.preventDefault()
            let s = el.lastSearch
            if (pageNumber) {
                pageNumber = parseInt(pageNumber)
                executeSearch(event, s.searchType, s.query, s.facetsMust, s.facetsShould, s.timeout, s.resultsPerSection, s.resultsPerPage, pageNumber)
            }
        }

        let renderPager = (maxPages) => {
            maxPages = maxPages ? maxPages : 1
            el.pager.prev.html = ''
            el.pager.next.html = ''
            let s = el.lastSearch
            if (maxPages > 1) {
                if (s.page > 1) {
                    el.pager.prev.html = `<li class="pager-previous">
                        <a class="unselectable" href="#">
                            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 1L1 8L8 15" stroke="#181818"/></svg>
                            Previous
                        </a>
                    </li>`
                }

                el.pager.dots.html = ''
                for (let pageNumber = 1; pageNumber <= maxPages; pageNumber++) {
                    let pagerDotClass = pageNumber === parseInt(el.lastSearch.page) ? 'active' : ''
                    el.pager.dots.html += `<li class="pager-dot ${pagerDotClass}">
                        <a class="page" href='#' data-page-number="${pageNumber}">${pageNumber}</a>
                    </li>`
                }

                if (s.page < maxPages) {
                    el.pager.next.html = `<li class="pager-next">
                        <a class="unselectable" href="#">
                            Next
                            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g transform="rotate(180)" transform-origin="50% 50%"><path d="M8 1L1 8L8 15" stroke="#181818"/></g></svg>
                        </a>
                    </li>`
                }
                el.pager.html = `<ul class="pager">
                    ${el.pager.prev.html}
                    ${el.pager.dots.html}
                    ${el.pager.next.html}
                </ul>`

                el.pagerElement.innerHTML = ''
                el.pagerElement.innerHTML = el.pager.html

                el.pagerElement.querySelectorAll('.pager-previous a').forEach((a) => {
                    a.addEventListener('click', onPagerPrev)
                })
                el.pagerElement.querySelectorAll('.pager-dot a').forEach((a) => {
                    a.addEventListener('click', onPagerDot)
                })
                el.pagerElement.querySelectorAll('.pager-next a').forEach((a) => {
                    a.addEventListener('click', onPagerNext)
                })
            }
        }

        let setSectionOrder = (resultSectionMaxScore) => {
            // Clone and break reference
            let resultSectionOrder = resultSectionMaxScore.slice(0)
            resultSectionOrder.sort(function (a, b) {
                return b.score - a.score
            })
            resultSectionOrder.forEach((object, index) => {
                let selector = object.key
                let section = el.realTimeSearchAll[0].querySelector(`[data-section="${selector}"]`)
                if (section) {
                    section.style.order = index
                }
            })
        }

        let renderResultTitle = (value) => {
            let title = ''
            if (value) {
                title = `<h3>${value}</h3>`
            }
            return title
        }

        let renderResultSnippet = (value) => {
            let snippet = ''
            if (value) {
                snippet = `<p>${value}</p>`
            }
            return snippet
        }

        let renderResultImage = (url, title) => {
            let image = ''
            if (url) {
                image = `<div class="result__image"><img src="${url}" title="${title}"/></div>`
            }
            return image
        }

        let closeSearch = () => {
            closeSearchContent()
            App.Nav.closeSearchBar()
        }

        let searchGoBack = (event) => {
            facetsMust = []
            facetsShould = el.lastSearch.facetsShould
            let query = el.searchFieldAll[0].value.trim()
            executeSearch(event, 'query', query, facetsMust, facetsShould, 0, 4)
        }

        let setGoBackVisibility = () => {
            let button = el.searchBackButtonAll[0]
            if (typeof el.lastSearch.facetsMust !== 'undefined' && el.lastSearch.facetsMust.length) {
                button.classList.add('show')
            } else {
                button.classList.remove('show')
            }
        }

        el.searchResultsAll.forEach(searchResults => {
            searchResults.removeEventListener('click', gaBindEventResultClick)
            searchResults.addEventListener('click', gaBindEventResultClick)
        })

        if (!el.body.classList.contains('real-time-search-processed')) {
            window.addEventListener('load', () => {
                showHidePlaceholder()
            })
            window.addEventListener('resize', () => {
                showHidePlaceholder()
            })
            window.addEventListener('popstate', () => {
                closeSearch()
            })

            // todo limit this to once()
            el.searchFieldAll.forEach(searchField => searchField.addEventListener('focus', (event) => {
                if (event.target.value && event.target.value.length) {
                    openSearchContent()
                    let query = event.target.value.trim()
                    executeSearch(event, 'query', query, [], [], 0, 4)
                }
            }))

            el.searchCloseButtonAll.forEach(searchCloseButton => searchCloseButton.addEventListener('click', closeSearch))
            el.searchBackButtonAll.forEach(searchBackButton => searchBackButton.addEventListener('click', searchGoBack))

            // Prevent the iOs Search key from actually submitting the form, we only
            // need the form and action so iOs keyboards to show the Search key.
            el.searchFieldFormAll.forEach(searchFieldForm => searchFieldForm.addEventListener('submit', (event) => {
                // This closes the iOs keyboard.
                el.searchFieldAll.forEach(searchField => searchField.blur())
                event.preventDefault()
            }))

            // todo: once()
            el.searchFieldAll.forEach(searchField => searchField.addEventListener('keyup', (event) => {
                // Press Escape.
                if (event.keyCode === 27) {
                    closeSearch()
                    return
                }

                // If the search box has been triggered to close we cancel that.
                window.clearTimeout(searchBoxCloseDelay)

                let query = el.searchFieldAll[0].value.trim()
                executeSearch(event, 'query', query, [], [], 500, 4)
            }))
        }

        el.body.classList.add('real-time-search-processed')
    }
}

export const RealTimeSearchComponent = {
    name: 'RealTimeSearch',
    componentClass: CLASSES.COMPONENT,
    Source: RealTimeSearch,
}
