import {throttle} from 'throttle-debounce'
import Emitter from 'utils/emitter'
import {GLOBAL_CONSTANTS} from 'utils/constants'
import Cookies from 'js-cookie'

export const POPUP_CONSTANTS = {
    EVENTS: {
        REGISTER_POPUP: 'register_popup',
        CLOSE_POPUP: 'close_popup',
    },
}

const CLASSES = {
    COMPONENT: 'body',
}

// This is used to help persist popup state between Barba page reloads
let STATE_CACHE = undefined

const POPUP_COOKIE_EXPIRE_DAYS = 7

export default class Popup {
    constructor(bodyWraper) {
        this.bodyWraper = bodyWraper

        this.pageStartTime = Date.now()

        if (STATE_CACHE === undefined) {
            STATE_CACHE = {
                visiblePopup: null,
                queuedPopups: [],
            }
            Emitter.on(POPUP_CONSTANTS.EVENTS.REGISTER_POPUP, this.registerPopup.bind(this))
            Emitter.on(POPUP_CONSTANTS.EVENTS.CLOSE_POPUP, this.setCookieAndClose.bind(this))

            this.throttleScroll = throttle(
                GLOBAL_CONSTANTS.TIMING.NAV_SCROLL_THROTTLE,
                this.checkForPopup.bind(this),
            )
            Emitter.on(GLOBAL_CONSTANTS.EVENTS.SCROLL, this.throttleScroll)
            this.popupIntervalChecker = setInterval(this.checkForPopup.bind(this), 500)
        }
        this.visiblePopup = STATE_CACHE.visiblePopup
        this.queuedPopups = STATE_CACHE.queuedPopups
    }

    tearDown() {
        STATE_CACHE.visiblePopup = this.visiblePopup
        STATE_CACHE.queuedPopups = this.queuedPopups
    }

    checkForPopup() {
        // Bail if a popup is visible, or if there's no pending popups, or if the body isn't ready yet.
        if (!document.body || this.queuedPopups.length === 0 || this.visiblePopup !== null) {
            return
        }

        const scrollPercent = window.scrollY / (document.body.offsetHeight - window.innerHeight)
        const timeOnPage = Date.now() - this.pageStartTime

        const availablePopups = this.queuedPopups.filter(function(popup) {
            // Bail if this has already been displayed
            if ('displayed' in popup && popup.displayed) {
                return
            }

            // Bail if this popup has a displayAfter and we haven't reached that time yet.
            if (popup.displayAfter && (timeOnPage < popup.displayAfter)) {
                return
            }

            // Bail if this popup has a scroll Threshold and we've not got there yet.
            if (popup.scrollThreshold && (scrollPercent < popup.scrollThreshold)) {
                return
            }

            // Bail if the pathnameRegex is set and it doesn't pass the test
            if (popup.pathnameRegex && !popup.pathnameRegex.test(window.location.pathname)) {
                return
            }

            // Is this already shown (check cookie)
            const cookieName = 'popup_' + popup.name
            if (Cookies.get(cookieName)) {
                return
            }

            return popup
        })

        if (availablePopups.length > 0) {
            // @TODO - sorting / weights / priority. Need later for cookie popup.
            this.visiblePopup = availablePopups[0]
            this.visiblePopup.el = this.makePopup(this.visiblePopup.popupContent())
            this.visiblePopup.el.classList.add('popup-' + this.visiblePopup.name)
            this.bodyWraper.appendChild(this.visiblePopup.el)

            this.visiblePopup.displayed = true
        }
    }

    registerPopup(popupDefinition) {
        this.queuedPopups.push(popupDefinition)
    }

    makePopup(content) {
        const wrapper = document.createElement('div')
        wrapper.id = 'popup_wrapper'

        wrapper.appendChild(content)

        let closeButton = document.createElement('span')
        closeButton.classList.add('close')
        closeButton.addEventListener('click', this.setCookieAndClose.bind(this))
        wrapper.appendChild(closeButton)

        return wrapper
    }

    setCookieAndClose() {
        if (this.visiblePopup) {
            // Set cookie to not show this popup again
            const cookieName = 'popup_' + this.visiblePopup.name
            Cookies.set(cookieName, 1, {
                expires: POPUP_COOKIE_EXPIRE_DAYS,
                sameSite: 'strict',
                secure: true,
            })

            this.closePopup()
        }
    }

    closePopup() {
        this.visiblePopup.el.classList.add('closing')
        // Wait 1.5s to clear the popup to allow the closing CSS animation to complete.
        setTimeout(function() {
            this.visiblePopup.el.remove()
            this.visiblePopup = null
        }.bind(this), 1500)
    }

}

export const PopupComponent = {
    name: 'Popup',
    componentClass: CLASSES.COMPONENT,
    Source: Popup,
}
