const CLASSES = {
    COMPONENT: '.swipe-scroll-list',
    PAGERS: '.carousel__pager',
    PAGER_ITEMS: '.carousel__pager li',
}

export default class ScrollList {
    constructor(el) {
        const that = this
        this.scrollList = el

        this.scrollPlaylist = this.scrollList.closest('.playlist')

        if (this.scrollPlaylist) {
            this.scrollListPagers = this.scrollPlaylist.querySelectorAll(CLASSES.PAGERS)
            if (this.scrollListPagers.length === 0) {
                this.scrollListPagers = undefined
            }
            else {
                this.scrollListPagerItems = this.scrollPlaylist.querySelectorAll(CLASSES.PAGER_ITEMS)

                // Pager dots click
                this.scrollListPagerItems.forEach(pagerItem => {
                    pagerItem.addEventListener('click', function () {
                        let targetIndex = parseInt(this.dataset.index)
                        that.scrollToListItem(targetIndex)
                    })
                })
            }
        }
        this.scrollListItems = this.scrollList.querySelectorAll('.carousel__list-item')

        let sibling = el
        // Need this silly !== null thing to satisfy a `no-cond-assign` lint check.
        while ((sibling = sibling.nextElementSibling) !== null) {
            if (sibling.classList.contains('scroll-list-button-next')) {
                sibling.addEventListener('click', function (e) {
                    // Todo we should use the same scrollTo code that I just used on the pager buttons
                    that.onPrevNextClick('next')
                    e.stopPropagation()
                    e.preventDefault()
                })
            } else if (sibling.classList.contains('scroll-list-button-previous')) {
                sibling.addEventListener('click', function (e) {
                    that.onPrevNextClick('prev')
                    e.stopPropagation()
                    e.preventDefault()
                })
            }
        }

        // Listen for scroll and update the left/right scroll distance data attributes
        this.scrollList.addEventListener('scroll', function () {
            that.updateScrollOffsets()
        }, {passive: true})

        // Listen for resize (as this will also alter the left/right scroll distances.
        window.addEventListener('resize', function () {
            that.updateScrollOffsets()
        }, {passive: true})

        // Trigger it on init
        this.updateScrollOffsets()
    }

    updateScrollOffsets() {
        this.scrollList.dataset.scrollLeft = this.scrollList.scrollLeft
        this.scrollList.dataset.scrollRight = this.scrollList.scrollWidth - this.scrollList.scrollLeft - this.scrollList.offsetWidth

        if (this.scrollList.dataset.scrollLeft < 1) {
            this.scrollList.dataset.scrollLeft = 0
        }
        // The right arrow wasn't disappearing because it was 0.5 instead of 0, so changed to < 1
        if (this.scrollList.dataset.scrollRight < 1) {
            this.scrollList.dataset.scrollRight = 0
        }

        // Update active pager
        if (this.scrollListItems.length) {
            let firstItemStyle = this.scrollListItems[0].currentStyle || window.getComputedStyle(this.scrollListItems[0])
            let itemWidth = parseFloat(firstItemStyle.width) + parseFloat(firstItemStyle.marginRight)
            let left = parseFloat(this.scrollList.scrollLeft)
            let targetIndex = Math.round(left / itemWidth)
            let lastPager = this.scrollListPagerItems[this.scrollListPagerItems.length - 1]
            let lastPagerIndex = lastPager.dataset.index
            // It doesn't always activate the last dot because it doesn't quite reach that 'page'
            if (targetIndex === lastPagerIndex - 1) {
                targetIndex = lastPagerIndex
            }
            if (this.scrollListPagers) {
                this.scrollListPagers.forEach(pager => {
                    let target = pager.querySelectorAll(`[data-index='${targetIndex}']`)
                    if (target.length) {
                        pager.querySelectorAll('li').forEach(e => e.classList.remove('is-active'))
                        target[0].classList.add('is-active')
                    }
                })
            }
        }
    }

    updateScrollListPagination(delta) {
        const scrollListItem = this.scrollList.querySelector('.scroll-list-item')
        const style = scrollListItem.currentStyle || window.getComputedStyle(scrollListItem)
        let offset = delta * (scrollListItem.offsetWidth + parseInt(style.marginRight))
        this.scrollList.scrollBy({left: offset, behavior: 'smooth'})
    }

    onPrevNextClick(prevOrNext) {
        // There are 2 separate pagers for tablet and desktop which used to have different numbers of items per page.
        if (this.scrollListPagers && window.innerWidth >= 768) {
            this.scrollListPagers.forEach(pager => {
                let pagerStyle = pager.currentStyle || window.getComputedStyle(pager)
                if (pagerStyle.display !== 'none') {
                    let activePagerItem = pager.querySelector('.is-active')
                    if (prevOrNext === 'next') {
                        let targetPagerItem = activePagerItem.nextElementSibling || activePagerItem
                        let targetIndex = targetPagerItem.dataset.index
                        this.scrollToListItem(targetIndex)
                    }
                    if (prevOrNext === 'prev') {
                        let targetPagerItem = activePagerItem.previousElementSibling || activePagerItem
                        let targetIndex = targetPagerItem.dataset.index
                        this.scrollToListItem(targetIndex)
                    }
                }
            })
        }
        else {
            let deltaConst = 1
            if (window.innerWidth >= 768) {
                deltaConst = 2
            }
            this.updateScrollListPagination(deltaConst * (prevOrNext === 'next' ? 1 : -1))
        }
    }

    scrollToListItem(targetIndex) {
        let targetListItem = this.scrollList.querySelector(`[data-index='${targetIndex}']`)
        let firstItemStyle = this.scrollListItems[0].currentStyle || window.getComputedStyle(this.scrollListItems[0])
        let gotoLeft = parseFloat(targetListItem.offsetLeft) - parseFloat(firstItemStyle.marginLeft)
        this.scrollList.scrollTo({left: gotoLeft, behavior: 'smooth'})
    }
}

export const ScrollListComponent = {
    name: 'ScrollList',
    componentClass: CLASSES.COMPONENT,
    Source: ScrollList,
}
