/**
 * NEW for the spotlight block
 * show image > show blank > show video > next slide
 *
 * |                    Carousel 5s                                      |
 * |     0         |        1        |          2       |       3        |
 * | fade in slide |    show blank   |    show video    | fade out slide |
 * |               |                 | update progress  |                |
 * |    2000ms      |     500ms       |   4000ms        |    800ms       |
 *
 */
import Bugsnag from '@bugsnag/js'

export let CLASSES = {
    CAROUSEL: '.js-founder-carousel',
    SLIDE: '.js-founder-slide',
    FOUNDER: '.js-founder-portrait',
    READ_MORE: '.js--read-more',
    READ_MORE_BUTTONS: '.founder__read-more__button',
    VIDEO: '.js-founder-video video',
    PROGRESS: '.js-founder-progress',
    UI: '.js-founder-ui',
    NEXT: '.js-founder-next',
    PREV: '.js-founder-prev',
}

export default class FounderCarousel {
    constructor(el) {
        this.founderPortraits = el
        this.founderCarousel = el.querySelectorAll(CLASSES.CAROUSEL)[0]
        this.founderUi = el.querySelectorAll(CLASSES.UI)[0]
        this.founderNext = el.querySelectorAll(CLASSES.NEXT)[0]
        this.founderPrev = el.querySelectorAll(CLASSES.PREV)[0]
        this.carousel = [
            {
                // step 0 show image
                timeoutID: 0,
                timeout: 250,
            },
            {
                // step 1 show blank
                timeoutID: 0,
                timeout: 250,
            },
            {
                // step 2 show video
                timeoutID: 0,
                timeout: 3750,
            },
            {
                // step 3 slide to next
                timeoutID: 0,
                timeout: 500,
            },
            {
                // step 4
                timeoutID: 0,
                timeout: 100,
            },
        ]

        this.blockCarousel = [
            {
                // step 0
                timeoutID: 0,
                timeout: 2000,
            },
            {
                // step 1
                timeoutID: 0,
                timeout: 500,
            },
            {
                // step 2
                timeoutID: 0,
                timeout: 4000,
            },
            {
                // step 3
                timeoutID: 0,
                // 500ms + 300ms for the transition delays
                timeout: 800,
            },
        ]

        this.carouselLength = this.founderCarousel.dataset.length
        this.allSlides = el.querySelectorAll(CLASSES.SLIDE)
        this.allVideos = el.querySelectorAll(CLASSES.VIDEO)
        this.allSmallReadMoreButtons = el.querySelectorAll(CLASSES.READ_MORE)
        this.allReadMoreButtons = el.querySelectorAll(CLASSES.READ_MORE_BUTTONS)
        this.slides = {}
        this.activeDelta = 0
        this.progressInterval = 0
        this.progressTimeoutMs = 250
        this.tabletOverlayTimeoutId = 0
        this.resetCarouselTimeoutId = 0
        this.resetCarouselReverseTimeoutId = 0
        this.carouselIsPlaying = true
        this.clickLatchTimeoutId = 0
        this.touchEndTimeoutId = 0
        this.firstPlay = true

        this.init = this.init.bind(this)
        this.onResize = this.onResize.bind(this)
        this.onSlideMouseEnter = this.onSlideMouseEnter.bind(this)
        this.onSlideMouseLeave = this.onSlideMouseLeave.bind(this)
        this.onSlideMouseDown = this.onSlideMouseDown.bind(this)
        this.onSlideTouchStart = this.onSlideTouchStart.bind(this)
        this.onSlideTouchEnd = this.onSlideTouchEnd.bind(this)
        this.onUiMouseDown = this.onUiMouseDown.bind(this)
        this.onCanPlayThrough = this.onCanPlayThrough.bind(this)
        this.onVideoEnded = this.onVideoEnded.bind(this)
        this.setActive = this.setActive.bind(this)
        this.resetCarousel = this.resetCarousel.bind(this)
        window.addEventListener('resize', this.onResize)
    }

    /**
     * Call super.init() from your instantiated class
     */
    init() {
        let offset = 0
        this.screenWidth = window.innerWidth

        if (this.allSlides) {
            this.allSlides.forEach(slide => {
                slide.removeEventListener('mouseenter', this.onSlideMouseEnter)
                slide.addEventListener('mouseenter', this.onSlideMouseEnter)
                slide.removeEventListener('mouseleave', this.onSlideMouseLeave)
                slide.addEventListener('mouseleave', this.onSlideMouseLeave)
                slide.removeEventListener('mousedown', this.onSlideMouseDown)
                slide.addEventListener('mousedown', this.onSlideMouseDown)
                slide.removeEventListener('touchstart', this.onSlideTouchStart)
                slide.addEventListener('touchstart', this.onSlideTouchStart)
                slide.removeEventListener('touchend', this.onSlideTouchEnd)
                slide.addEventListener('touchend', this.onSlideTouchEnd)

                const delta = slide.dataset.delta
                const founder = slide.querySelectorAll(CLASSES.FOUNDER)[0]
                const smallReadMoreButton = document.querySelector(`${CLASSES.READ_MORE}[data-delta="${delta}"]`)
                const video = slide.querySelectorAll(CLASSES.VIDEO)[0]
                const progress = slide.querySelectorAll(CLASSES.PROGRESS)[0]
                const style = window.getComputedStyle(slide)

                if (progress) {
                    progress.style.transition = `background-position ${this.progressTimeoutMs}ms linear`
                }
                if (video) {
                    video.style.transition = 'opacity 500ms ease'
                    video.style.opacity = '0'
                    // No videos preload on mobile devices so need to manually trigger
                    video.load()
                    video.removeEventListener('canplaythrough', this.onCanPlayThrough)
                    video.addEventListener('canplaythrough', this.onCanPlayThrough)
                    video.removeEventListener('ended', this.onVideoEnded)
                    video.addEventListener('ended', this.onVideoEnded)
                }
                this.slides[delta] = {
                    slide: slide,
                    founder: founder,
                    video: video,
                    canPlayThrough: false,
                    smallReadMoreButton: smallReadMoreButton,
                    progress: progress,
                }
                if (parseInt(delta) === -1) {
                    const slide = this.getSlide(delta)
                    if (slide) {
                        slide.offset = -parseFloat(style.width)
                    }
                } else {
                    const slide = this.getSlide(delta)
                    if (slide) {
                        slide.offset = offset
                    }
                    offset += parseFloat(style.width)
                }
            })
        }
        this.founderUi.removeEventListener('mousedown', this.onUiMouseDown)
        this.founderUi.addEventListener('mousedown', this.onUiMouseDown)
        this.attachReadMoreHoverEvents()
        this.resetCarousel()
        this.startCarousel(0)
        if (this.isSmallTabletMode()) {
            this.showSmallReadMoreButton(this.activeDelta)
        }
    }

    getSlide(index) {
        const slide = this.slides[index]
        if (!slide) {
            const presentKeys = Object.keys(this.slides)
            Bugsnag.notify(new Error(`Slide index "${index}" not found in "${presentKeys}".`))
            return false
        }

        return slide
    }

    onResize() {
        let newWidth = window.innerWidth

        if (newWidth !== this.screenWidth) {
            this.screenWidth = newWidth
            this.tearDown()
            this.init()
        }
    }

    attachReadMoreHoverEvents() {
        this.allReadMoreButtons.forEach(button => {
            button.addEventListener('mouseenter', this.onReadMoreEnter.bind(this))
            button.addEventListener('mouseleave', this.onReadMoreLeave.bind(this))
        })
    }

    removeReadMoreHoverEvents() {
        this.allReadMoreButtons.forEach(button => {
            button.removeEventListener('mouseenter', this.onReadMoreEnter.bind(this))
            button.removeEventListener('mouseleave', this.onReadMoreLeave.bind(this))
        })
    }

    onReadMoreEnter() {
        this.firstPlay = false
        this.step0()
        this.resetTimers()
        this.setCarouselIsPaused()
    }

    onReadMoreLeave() {
        this.startCarousel(2)
    }

    onSlideMouseEnter() {}

    onSlideMouseLeave() {}

    onSlideMouseDown() {}

    onSlideTouchStart() {}

    onSlideTouchEnd() {}

    onUiMouseDown() {
        if (!this.isDesktopMode()) {
            if (this.carouselIsPlaying) {
                this.stopCarousel()
            } else {
                this.startCarousel(1)
            }
        }
    }

    onVideoEnded(event) {
        if (this.getSlideDelta(event) === this.activeDelta) {
            // Only advance the carousel if it hasn't been advanced yet,
            // meaning the index of the slide belonging to the video that just ended
            // is still the same as activeDelta. If it's not, do nothing
            this.startCarousel(3)
        }
    }

    onCanPlayThrough(event) {
        let delta = this.getSlideDelta(event)
        const slide = this.getSlide(delta)
        if (slide) {
            slide.video.removeEventListener('canplaythrough', this.onCanPlayThrough)
            slide.canPlayThrough = true
            slide.video.style.opacity = '1'
        }
    }

    setActive(delta) {
        if (parseInt(delta) >= this.carouselLength) {
            delta = 0
        }
        if (this.isSmallTabletMode()) {
            this.showSmallReadMoreButton(delta)
        }
        this.fadeOutSlides()
        this.activeDelta = delta
    }

    fadeOutSlides() {
        Object.keys(this.slides).forEach((d) => {
            this.slides[d].slide.classList.remove('is-active')
        })
    }

    fadeInSlide(delta) {
        Object.keys(this.slides).forEach((d) => {
            this.slides[d].slide.style.zIndex = 0
        })
        const slide = this.getSlide(delta)
        if (slide) {
            slide.slide.style.zIndex = 1
            slide.slide.classList.add('is-active')
        }
    }

    resetCarousel() {
        this.disableAnimation()
        this.activeDelta = 0
        this.resetTimers()
        this.fadeOutSlides()
    }

    enableAnimation = () => {
        this.founderCarousel.style.transition = 'transform 1000ms ease'
    }

    disableAnimation = () => {
        this.founderCarousel.style.transition = 'none'
    }

    getSlideDelta = (event) => {
        if (event.target.matches((CLASSES.SLIDE))) {
            return parseInt(event.target.dataset.delta)
        } else {
            return parseInt(event.target.closest(CLASSES.SLIDE).dataset.delta)
        }
    }

    isDesktopMode = () => {
        // This only applies to the FounderPortraits block since it has different functionality on desktop and tablet
        return window.getComputedStyle(this.founderUi).display === 'none'
    }

    isWideTabletMode = () => {
        return window.innerWidth >= 1024 && !this.isDesktopMode()
    }

    isTabletMode = () => {
        return window.innerWidth >= 640 && window.innerWidth < 1024 && !this.isDesktopMode()
    }

    isMobileMode = () => {
        return window.innerWidth < 640
    }

    isSmallTabletMode = () => {
        return window.innerWidth < 768
    }

    hideSmallReadMoreButtons = () => {
        this.allSmallReadMoreButtons.forEach(button => {
            button.style.display = 'none'
        })
    }
    showSmallReadMoreButton = (delta) => {
        this.hideSmallReadMoreButtons()
        const slide = this.getSlide(delta)
        if (slide && slide.smallReadMoreButton) {
            slide.smallReadMoreButton.style.display = 'flex'
        }
    }

    founderIsOnScreen = (delta) => {
        if (this.isDesktopMode() || this.isWideTabletMode()) {
            return delta >= this.activeDelta && delta <= this.activeDelta + 2
        } else {
            return delta === this.activeDelta
        }
    }

    founderIsOffScreenToLeft = (delta) => {
        const carouselStyle = window.getComputedStyle(this.founderCarousel)
        const matrix = new DOMMatrixReadOnly(carouselStyle.transform)
        const translateX = matrix.m41
        if (typeof translateX === 'number') {
            const slide = this.getSlide(delta)
            if (slide) {
                // Prevents resetCarouselReverse also triggering an overlay hover
                return Math.abs(translateX) - slide.offset > 20
            }
        }
        return false
    }

    founderIsNext = (delta) => {
        if (this.isDesktopMode() || this.isWideTabletMode()) {
            return delta === this.activeDelta + 3
        } else {
            return delta === this.activeDelta + 1
        }
    }

    founderIsPrev = (delta) => {
        return this.founderIsOffScreenToLeft(delta)
    }

    startCarousel = (startStep) => {
        this.stopCarousel()
        if (`step${startStep}` in this) {
            this[`step${startStep}`]()
        } else {
            this.step0()
        }
        this.setCarouselIsPlaying()
    }

    pauseCarousel = () => {
        this.resetTimers()
        this.setCarouselIsPaused()
    }

    stopCarousel = () => {
        this.setAllStateImage()
        this.pauseCarousel()
    }

    resetTimers = () => {
        this.blockCarousel.forEach(step => {
            clearTimeout(step.timeoutID)
            step.timeoutID = 0
        })
        this.carousel.forEach(step => {
            clearTimeout(step.timeoutID)
            step.timeoutID = 0
        })
    }

    firstPlayDelay = (timeout) => {
        if (this.firstPlay) {
            timeout += 1400 // 1.4s delay on first play to allow for headline fade in
            this.firstPlay = false
        }
        return timeout
    }

    step0 = () => {
        this.fadeInSlide(this.activeDelta)
        this.setStateImage(this.activeDelta)
        let timeout = this.firstPlayDelay(this.blockCarousel[0].timeout)
        this.blockCarousel[0].timeoutID = window.setTimeout(this.step1, timeout)
    }

    step1 = () => {
        this.setStateBlank(this.activeDelta)
        this.doStep1()
    }

    doStep1 = () => {
        this.blockCarousel[1].timeoutID = window.setTimeout(this.step2, this.blockCarousel[1].timeout)
    }

    step2 = () => {
        this.setStateVideo(this.activeDelta)
        this.blockCarousel[2].timeoutID = window.setTimeout(this.step3, this.blockCarousel[2].timeout)
    }

    step3 = () => {
        this.setActive(parseInt(this.activeDelta) + 1)
        this.blockCarousel[3].timeoutID = window.setTimeout(this.step0, this.blockCarousel[3].timeout)
    }

    setCarouselIsPlaying = () => {
        this.carouselIsPlaying = true
        this.founderUi.classList.remove('is-paused')
    }

    setCarouselIsPaused = () => {
        this.carouselIsPlaying = false
        this.founderUi.classList.add('is-paused')
    }

    getFounderState = (delta) => {
        const slide = this.getSlide(delta)
        if (!slide) {
            return
        }
        if (slide.founder.dataset.stateOverlay === '1') {
            return 'overlay'
        } else {
            return slide.founder.dataset.state
        }
    }

    setAllStateImage = () => {
        Object.keys(this.slides).forEach(delta => this.setStateImage(delta))
    }

    setStateBlank = (delta) => {
        const slide = this.getSlide(delta)
        if (slide && slide.founder) {
            slide.founder.dataset.state = 'blank'
            slide.founder.dataset.stateOverlay = '0'
        }
    }

    setStateImage = (delta) => {
        this.pauseVideo(delta)
        const slide = this.getSlide(delta)
        if (slide && slide.founder) {
            slide.founder.classList.remove('no-transition')
            slide.founder.dataset.state = 'image'
            slide.founder.dataset.stateOverlay = '0'
        }
    }

    setStateVideo = (delta) => {
        const slide = this.getSlide(delta)
        if (!slide) {
            return
        }
        if (slide.canPlayThrough) {
            slide.video.style.opacity = '1'
            slide.founder.classList.remove('no-transition')
            slide.founder.dataset.state = 'video'
            slide.founder.dataset.stateOverlay = '0'
            this.stopAllVideosExcept(delta)
            slide.video.play().then().catch(error => {
                if (error.name === 'NotAllowedError') {
                    // Safari has a setting 'Never autoplay' that makes this throw an error, do nothing
                } else {
                    Bugsnag.notify(error)
                }
            })
            clearInterval(this.progressInterval)
            this.progressInterval = window.setInterval(this.updateProgress, this.progressTimeoutMs, delta)
        } else {
            // if not ready for playing yet, show image instead
            this.setStateImage(delta)
        }
    }

    setStateOverlay = (delta) => {
        this.pauseVideo(delta)
        const slide = this.getSlide(delta)
        if (slide) {
            return slide.founder.dataset.stateOverlay = '1'
        }
    }

    gotoDeltaAndShowOverlay = (delta) => {
        if (this.founderIsOnScreen(delta)) {
            this.setStateOverlay(delta)
        } else if (this.founderIsNext(delta)) {
            this.setActive(this.activeDelta + 1)
            // Make sure we trigger the correct founder not the duplicate after looping
            if (this.isWideTabletMode() || this.isDesktopMode()) {
                if (delta === 2 + parseInt(this.carouselLength)) {
                    delta = 2
                }
            } else {
                delta = `${delta % this.carouselLength}`
            }

            clearTimeout(this.tabletOverlayTimeoutId)
            this.tabletOverlayTimeoutId = window.setTimeout(this.setStateOverlay, 1000, delta)
        }
    }

    pauseVideo = (delta) => {
        const slide = this.getSlide(delta)
        if (slide && slide.video) {
            slide.video.pause()
        }
        clearInterval(this.progressInterval)
    }

    stopVideo = (delta) => {
        const slide = this.getSlide(delta)
        if (slide) {
            slide.video.pause()
            slide.video.currentTime = 0
            this.resetProgress(delta)
        }
    }

    stopAllVideosExcept = (exceptDelta) => {
        Object.keys(this.slides).forEach((delta) => {
            if (parseInt(delta) !== parseInt(exceptDelta)) {
                this.stopVideo(delta)
            }
        })
    }

    stopAllVideos = () => {
        Object.keys(this.slides).forEach(delta => this.stopVideo(delta))
    }

    resetProgress = (delta) => {
        this.setProgress(delta, 0)
    }

    setProgress = (delta, percent) => {
        const slide = this.getSlide(delta)
        if (slide) {
            slide.progress.style.transition = `background-position ${this.progressTimeoutMs}ms linear`
            slide.progress.style.backgroundPosition = `${percent}%`
        }
    }

    updateProgress = (delta) => {
        const slide = this.getSlide(delta)
        if (slide) {
            let video = slide.video
            this.setProgress(delta, video.currentTime / video.duration * 100)
        }
    }

    disableNextOffScreenFounder = () => {
        if (this.isDesktopMode()) {
            const slide = this.getSlide(this.activeDelta + 3)
            if (slide) {
                slide.founder.classList.add('is-disabled')
            }
        }
    }

    disablePrevOffScreenFounder = () => {
        if (this.isDesktopMode()) {
            const slide = this.getSlide(this.activeDelta - 1)
            if (slide) {
                slide.founder.classList.add('is-disabled')
            }
        }
    }

    enableAllFounders = () => {
        Object.keys(this.slides).forEach(delta => {
            this.slides[delta].founder.classList.remove('no-transition')
            this.slides[delta].founder.classList.remove('is-disabled')
        })
    }

    showNextBtn = () => {
        if (this.isDesktopMode()) {
            this.founderNext.classList.add('is-active')
            this.enableAllFounders()
            this.disableNextOffScreenFounder()
        }
    }

    hideNextBtn = () => {
        this.enableAllFounders()
        this.founderNext.classList.remove('is-active')
    }

    showPrevBtn = () => {
        if (this.isDesktopMode()) {
            this.founderPrev.classList.add('is-active')
            this.enableAllFounders()
            this.disablePrevOffScreenFounder()
        }
    }

    hidePrevBtn = () => {
        this.enableAllFounders()
        this.founderPrev.classList.remove('is-active')
    }

    tearDown() {
        this.stopCarousel()
        this.stopAllVideos()
        this.hideSmallReadMoreButtons()
        this.removeReadMoreHoverEvents()
        clearInterval(this.progressInterval)
        this.progressInterval = 0
        clearTimeout(this.tabletOverlayTimeoutId)
        this.tabletOverlayTimeoutId = 0
        clearTimeout(this.resetCarouselTimeoutId)
        this.resetCarouselTimeoutId = 0
        clearTimeout(this.resetCarouselReverseTimeoutId)
        this.resetCarouselReverseTimeoutId = 0
        clearTimeout(this.clickLatchTimeoutId)
        this.clickLatchTimeoutId = 0
        clearTimeout(this.touchEndTimeoutId)
        this.touchEndTimeoutId = 0

        window.removeEventListener('resize', this.init)
        this.founderUi.removeEventListener('mousedown', this.onUiMouseDown)

        if (this.allSlides) {
            this.allSlides.forEach(slide => {
                slide.removeEventListener('mouseenter', this.onSlideMouseEnter)
                slide.removeEventListener('mouseleave', this.onSlideMouseLeave)
                slide.removeEventListener('mousedown', this.onSlideMouseDown)
                slide.removeEventListener('touchstart', this.onSlideTouchStart)
                slide.removeEventListener('touchend', this.onSlideTouchEnd)
            })
        }
        if (this.allVideos) {
            this.allVideos.forEach(video => {
                video.removeEventListener('canplaythrough', this.onCanPlayThrough)
            })
        }
    }
}